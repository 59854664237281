import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import { useColorMode } from 'theme-ui'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { Storelocator } from '~/components/StoreLocator'

const StoreLocatorPage = ({ data }) => {
  const [colorMode, setColorMode] = useColorMode()
  useEffect(() => {
    setColorMode('FAQ')
  })
  const { page } = data
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script')

      script.src = 'https://lets.shop/productFirstSnippet.js?id=destini-locator'
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])
  return (
    <Layout>
      <Metadata title="store-locator" />
      <Storelocator page={page} />
    </Layout>
  )
}

export default StoreLocatorPage

export const query = graphql`
  query StorelocatorPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "store-locator" }
      node_locale: { eq: $locale }
    ) {
      ...ContentPageFragment
    }
  }
`
